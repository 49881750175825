.dark-mode {
  $sticky-row-bg-color: #232323;

  .app .main__content .card {
    background-color: rgba(255, 255, 255, 0.07);
  }
  .app .main__content .card-header {
    background-color: unset
  }

 h5, .h5 {
    color: #fff;
  }

  .table-striped {
    tbody {
      tr:nth-of-type(2n+1) {
        background-color: rgba(255, 255, 255, 0.03);
      }
      tr:nth-of-type(2n) {
        background-color: transparent;
      }
    }
  }

  .sticky-header-table table th {
    background-color: $sticky-row-bg-color;
    box-shadow: inset 0 -1px 0 #bbb;
  }

  .sticky-summary-row table tr:last-of-type td {
    background-color: $sticky-row-bg-color;
  }


  .bg-white:not(.no-transparent) {
    background-color: transparent !important;
  }

  svg.recharts-surface {
    path.recharts-tooltip-cursor {
      fill: rgba(40, 42, 43, 0.5)
    }

    text {
      fill: #d1cdc7
    }

    .recharts-pie {
      .recharts-pie-sector {
        .recharts-sector {
          stroke: transparent;
        }
      }
    }
  }

  div.recharts-tooltip-wrapper {
    div {
      background-color: #282A2B !important;
    }
  }

  .app {
    background-color: #121212;

    .sticky-header {
      background-color: #121212 !important;
    }

    & > .header {
      background-color: #282828 !important
    }

    .main {
      & > .main__content {
        background-color: unset !important;
      }

      & > .main__nav {
        background-color: #1E1E1E !important;

        a:hover {
          background-color: rgba(255, 255, 255, 0.08) !important;
        }

        .is-active {
          background-color: rgba(255, 255, 255, 0.16) !important;
        }
      }
    }
  }

  body {
    $dark-mode-text-color: rgba(255, 255, 255, 0.9);
    color: $dark-mode-text-color;

    h1 {
      color: $dark-mode-text-color;
    }
  }

  .table-responsive::-webkit-scrollbar-thumb {
    border-color: rgb(255 255 255 / 7%);
  }

  .custom-pdftron-toolbar {
    color: #212529;

    .btn-warning {
      color: #212529;
      background-color: #ffc107;
      border-color: #ffc107;
    }

    .btn-secondary {
      color: #303030;
      background-color: #eceeef;
      border-color: #eceeef;
    }
  }

  .pdftron-loading {
    .skeleton.is-animating {
      .skeleton-title, .skeleton-row {
        background-image: linear-gradient(90deg, rgb(242, 242, 242) 25%, rgb(230, 230, 230) 37%, rgb(242, 242, 242) 63%);
        animation: swiping 3s ease infinite;
        background-color: #f2f2f2
      }
    }

    .bg-white {
      background-color: white !important;
    }
  }

  .nav-link {
    color: white !important;
  }

  .form-check-label {
    color: #a8a095;
  }
  
  .im-table .row--even {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .im-table .row--odd {
    background-color: transparent;
  }

  .im-table [role=rowgroup] [role=row]:hover [role=cell] {
    background-color: transparent;
  }

  .main.not-navigating.Opportunities, .offering-body {
    background: black;
  }
}