.pdf-signature-input-container {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
}

.pdf-signature-input {
  border-bottom: 1px solid black;
  font-family: 'Cookie';
  font-size: 1.5rem;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding-bottom: 0;
}

.pdf-signature-input::placeholder {
  font-size: 1.5rem;
}