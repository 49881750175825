@import "~/styles/sharedUtilities.scss";

.main__content.documents {
  object {
    flex: 1 1 auto;
    margin-bottom: 1rem;
  }
}

h4 {
  padding-top: 1rem;
  margin-bottom: 0;
  font-weight: normal;
}

.document-name {
  font-size: 1.25rem;
  font-weight: 500;
}

.authorized-by-input {
  font-family: "Dancing Script", cursive;
  font-size: 2rem;
  line-height: normal;
}

.signature-input {
  border-bottom: 1px solid black;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding-bottom: 0;
  max-width: 25rem;
  @media (max-width: 576px) {
    max-width: 90%;
  }
}

.signature-input:focus {
  outline: 0;
}

.signature-line {
  font-size: 1.25rem;
  float: left;
  padding-top: 1rem;
  padding-right: 0.25rem;
}

.title-line {
  font-size: 1.25rem;
  float: left;
  padding-top: 0.5rem;
  padding-right: 0.25rem;
}

.signature-block {
  max-width: 1000px;
}

@include media-breakpoint-up(lg) {
  .signature-body {
    padding: 0.5rem 1.5rem;
    max-width: 990px;
  }
}

.exclude-from-dark-mode {
  background-color: #ffffff !important;
  color: #212529;
}
