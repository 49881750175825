$im-table-gradient-start-color: #d1d1d1 !default;
$im-table-gradient-end-color: transparent !default;
$im-table-shading-z-index: 5;

.im-table {
  font-variant-numeric: tabular-nums;
  margin-bottom: 0;
  position: relative;

  &:before,
  &:after {
    content: "";
    display: block;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: opacity 0.3s;
    width: 10px;
    z-index: $im-table-shading-z-index;
  }

  &:before {
    background: linear-gradient(to right,
        $im-table-gradient-start-color,
        $im-table-gradient-end-color );
    left: var(--scrollable-start, 0);
  }

  &:after {
    background: linear-gradient(to left,
        $im-table-gradient-start-color,
        $im-table-gradient-end-color );
    right: 0;
  }

  &.is-left-scrollable {
    &:before {
      opacity: 1;
    }
  }

  &.is-right-scrollable {
    &:after {
      opacity: 1;
    }
  }

  &.is-vertical-scrollable {
    .rowgroup--header {
      width: calc(100% - var(--scrollbar-size, 0));
    }

    .rowgroup--footer {
      width: calc(100% - var(--scrollbar-size, 0));
    }
  }

  &.is-vertical-scrollable:after {
    right: var(--scrollbar-size, 0);
  }

  [role=table] {
    width: 100%;
  }

  .rowgroup--header {
    width: 100%;
    overflow: hidden;

    [role=columnheader] {
      font-weight: bold;
      border-top: 0;
      border-bottom: 0px;
      -webkit-box-shadow: inset 0 -1px 0 #b9b9b9;
      box-shadow: inset 0 -1px 0 #b9b9b9;
    }
  }

  .rowgroup--footer {
    width: 100%;
    overflow: hidden;

    [role=cell] {
      font-weight: bold;
      border-top: 0;
      border-bottom: 0px;
      -webkit-box-shadow: inset 0 1px 0 #b9b9b9;
      box-shadow: inset 0 1px 0 #b9b9b9;
    }
  }

  [role=columnheader],
  [role=cell] {
    //.table td, .table th {
    margin: 0;
    padding: 0.65rem;
    display: flex;
    align-items: center;
    border-top: 1px solid #f8f9fa;
  }

  .row--odd {
    background-color: #ffffff;
  }

  .row--even {
    background-color: #f8f9fa;
  }

  [role=rowgroup] [role=row]:hover [role=cell] {
    background-color: #E3FCFF;
  }

  .row--selected *[role=cell] {
    background-color: #d5edf1;
    border-color: #f8f9fa;
  }

  tr.table-info {
    background-color: #d5edf1 !important;
    border-color: #f8f9fa;
  }

  .btn-link {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    text-align: left;
    word-wrap: break-word;
  }
}

.sticky-actions-table {
  &.im-table:after {
    right: 49px;
  }

  &.im-table.is-vertical-scrollable:after {
    right: calc(49px + var(--scrollbar-size, 0));

    // getting the scrollbar size does not account for the webkit-scrollbar change above because it
    // gets the size of a scrollbar at the root, but these styles apply specifically to the table.

    // safari doesnt support `@supports selector` so catch it here
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) and (stroke-color: transparent) {
        right: calc(49px + 12px);
      }
    }

    // detect webkit and add a pixel for weird chrome issues
    @supports #{"\selector(::-webkit-scrollbar)"} {
      right: calc(49px + 12px + 1px);
    }
  }

  & [role=cell]:last-child,
  [role=columnheader]:last-child {
    position: sticky;
    right: 0;
    // fixes firefox borders being hidden
    // https://stackoverflow.com/questions/41882616/why-border-is-not-visible-with-position-sticky-when-background-color-exists
    background-clip: padding-box;
    width: 2rem;
    padding: 0;
    text-align: center;
    z-index: $im-table-shading-z-index - 1;
    // add a delay so when it goes away it doesn't momentarily get covered by next rows actions
    transition-delay: 0.1s;
    transition-property: z-index;

    // add a z-index on focus - must be done this way because each row has its own stacking context
    // due to the sticky positioning, and adding a z-index to the dropdown is scoped within that
    // we need to increase the z-index of the entire actions part of the focused row instead
    &:focus-within {
      z-index: $im-table-shading-z-index + 1;
    }
  }

  & [role=columnheader]:last-child {
    background: #fff;
    background-clip: padding-box;
    // make the header appear above the cells so they scroll under it
    z-index: $im-table-shading-z-index;
  }

  // will-change creates a new stacking context and causes the actions to appear below the responsive shading
  // looks like react-window does not want to remove it for perf reasons https://github.com/bvaughn/react-window/issues/47
  // but we can disable it when there is focus within the table (e.g. when you click to open the dropdown)
  // and rely on the fact that if the user scrolls enough to encounter the perf issues, the element they focused
  // will be removed from the DOM (cause we're using a virtualized table), which will bring the perf benefits back
  .rowgroup--body>div:focus-within {
    will-change: unset !important;
  }
}