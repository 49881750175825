.pdf-form-label {
  width: 100%;
  height: 100%;
  position: relative;
}

.pdf-form-label-feedback {
  position: absolute;
  width: 180px;
  top: 100%;
  left: 0;
}
