.pdf-textbox-container {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.draggable-layer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.required::placeholder {
  color: #dc3545; 
  font-size: 80%; 
}