.combobox.dropdown:not(.show):has(button:focus),
.combobox.dropdown:not(.show):has(input:focus) {
  border-color: #08c #0099e6 #0099e6;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  outline: none;
}

.combobox.dropdown .input-group button {
  background: transparent;
  border: 0;
  font-weight: bold;
}

.combobox.dropdown .input-group button:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

.combobox.dropdown .input-group {
  border: 1px solid rgb(217, 217, 217);
  border-radius: 4px;
}

.combobox.dropdown.show .input-group {
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
}

.combobox.dropdown input {
  border: none;
  margin-right: 0.5em;
  outline: none;
}

.combobox.dropdown input:focus {
  box-shadow: none;
}

.combobox.dropdown input::placeholder {
  color: #aaa;
}

.combobox.dropdown [role=listbox] {
  border-top: 0;
  border-radius: 0 0 4px 4px;
  margin-top: 0;
  overflow-y: auto;
  padding: 0;
  width: 100%;
}
