// copied from https://github.com/appfolio/apm_bundle/commit/05fb3f238419aeb503e564168eb158d52000d07b#diff-c4824669a24211b82395162139a15fbb

$responsive-table-gradient-start-color: #d1d1d1 !default;
$responsive-table-gradient-end-color: transparent !default;
$responsive-table-shading-z-index: 5;

.responsive-table {
  margin-bottom: 1em;
  position: relative;

  &:before,
  &:after {
    content: "";
    display: block;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: opacity 0.3s;
    width: 10px;
    z-index: 1;
  }

  &:before {
    background: linear-gradient(
      to right,
      $responsive-table-gradient-start-color,
      $responsive-table-gradient-end-color
    );
    left: 0;
  }

  &:after {
    background: linear-gradient(
      to left,
      $responsive-table-gradient-start-color,
      $responsive-table-gradient-end-color
    );
    right: 0;
  }

  &.is-left-scrollable {
    &:before {
      opacity: 1;
    }
  }

  &.is-right-scrollable {
    &:after {
      opacity: 1;
    }
  }
}

  .sticky-header-table-small .table-responsive {
    // Lenka made me do it
    max-height: 225px;
  }
  .sticky-header-table-big .table-responsive {
    // Lenka made me do it
    max-height: 540px;
  }

  .sticky-header-table .table-responsive {
    overflow: auto;
    // border: 1px solid #f8f9fa;
    -ms-overflow-style: scrollbar;
    border-radius: 0.25rem;
  }

  .table-responsive::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  .table-responsive::-webkit-scrollbar:vertical {
    width: 12px;
  }

  .table-responsive::-webkit-scrollbar:horizontal {
    height: 12px;
  }

  .table-responsive::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 9px;
    border: 2px solid #f3f4f6;
  }

  .table-responsive::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  .sticky-header-table table {
    margin-bottom: 0;
  }

  .sticky-header-table table th {
    top: 0px;
    position: sticky;
    background-color: #ffffff;
    border-bottom: 0;
    z-index: $responsive-table-shading-z-index - 2;
    box-shadow: inset 0 -1px 0 #b9b9b9;
  }

  .sticky-summary-row table tr:last-of-type {
    bottom: 0px;
    position: sticky;
    background-color: #ffffff;
    z-index: $responsive-table-shading-z-index;
    -webkit-box-shadow: inset 0 1px 0 #b9b9b9;
    box-shadow: inset 0 1px 0 #b9b9b9;  
  }
