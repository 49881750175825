@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

$transparent-black: rgba(0, 0, 0, 0.5);
$transparent-primary: rgba(234, 234, 234, 0.9);
// tightly coupled with DEFAULT_HEIGHT DEFAULT_WIDTH in ./SignatureDocumentBuilder/helpers.ts
$default-with: 150px;
$default-height: 36px;

$base-z-index: 1;
$space-between-pages: 15px;

.preview-container {
  position: relative;
}

.checkbox-field,
.initials-field,
.signature-field,
.co-signature-field,
.date-field,
.textbox-field {
  width: 100%;
  height: 100%;
}

.sticky-next-field-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 99;

  button {
    height: inherit;
    border-radius: 0;
  }
}

$sticky_next_field_btn_bp: map-get($grid-breakpoints, "lg");

.sticky-next-field-btn {
  display: none;

  @media (max-width: $sticky_next_field_btn_bp) {
    display: block;
  }
}

.sticky-next-field-btn-space {
  display: none;
  height: 3em;

  @media (max-width: $sticky_next_field_btn_bp) {
    display: block;
  }
}

.next-field-btn,
.download-btn {
  @media (max-width: $sticky_next_field_btn_bp) {
    display: none;
  }
}

.custom-pdftron-toolbar {
  max-width: 1000px;
  border: 1px solid #adb5bd;
  margin: 0;
  background-color: #dadada !important;
  display: flex;
  align-items: center;
  .h6 {
    @media (max-width: $sticky_next_field_btn_bp) {
      font-size: $h6-font-size * 3 /4;
    }
  }
}

.sticky-toolbar {
  position: sticky;
  top: 0px;
  z-index: 5;
  max-width: 1000px;
}

.document-title {
  text-align: center;
  padding: 12px 0px;
  margin: 0;
}
