$page-edge-margin: calc(1rem + 15px);
$negative-page-edge-margin: calc(-1 * #{$page-edge-margin});
$active-tab-top-border-size: 4px;
$tab-background-color: transparent;

.tab-navigation {
  .nav-tabs {
    font-size: 1.1rem;
  }

  .nav-link {
    color: #303030;
    padding: 0.5rem 1rem 0.3rem 1rem;

    &:hover {
      border-color: rgba(0, 0, 0, 0);
      color: #0275d8;
    }
    &.active:hover {
      color: #303030;
    }

    &.active {
      background-color: $tab-background-color;
      border-bottom: 5px solid #e5b056;
      font-family: "Avenir", "Nunito Sans", sans-serif;
      font-weight: 700;
      border-top: none;
      border-right: none;
      border-left: none;
    }
  }

  .nav-tabs .nav-item {
    border: none;
  }

  .tab-content {
    background-color: $tab-background-color;
    padding: 1rem $page-edge-margin 0 $page-edge-margin;
    margin: 0 $negative-page-edge-margin;
  }
}
