@import "~/styles/sharedUtilities.scss";

.summary-box .card-group {
  margin: 0 !important;
}

.asset .card-body {
  padding: 2rem;
}

.card.asset {
  margin-left: 3rem;
  margin-right: 3rem;
  @include media-breakpoint-down(md) {
    margin-left: 0;
    margin-right: 0;
  }
}

.asset .summary-container {
  display: flex;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: center;
  }

  .photo {
    align-items: left;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-down(md) {
      padding: 1rem;
      width: 100%;
    }
  }
}

.asset .content-container {
  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: center;
  }
}

.flex-grow-1 {
  padding-left: 0.5rem;
}

.asset-address, .asset-update {
  margin-top: .5rem;
  margin-bottom: 1rem;
  font-family: "Avenir", "Nunito Sans", sans-serif;

  @include media-breakpoint-down(md) {
    text-align: center;
  }
}

.asset-description {
  margin-bottom: 0;
  margin-left: -0.5rem;
  margin-top: 0.5rem;
  white-space: pre-wrap;
  word-break: break-word;
}

.summary-box {
  margin-bottom: 1.5rem;
}

.asset-summary-item {
  padding-left: 1rem;
  padding-right: 2rem;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: auto;
  width: 50%;

  @include media-breakpoint-down(xs) {
    @media screen {
      text-align: left;
      border: 0;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      padding-right: 1rem;
    }
  }

  .item{
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
  .label{
    margin-right: 0.5rem;
    text-align: right;
    min-width: max-content;
  }
  .value{
    align-self: left;
    min-width: max-content;
  }
}

.performance-item {
  text-align: center;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  display: block;
  @include media-breakpoint-down(sm) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
