.pdf-checkbox-container {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10%;
}

/* Hide the browser's default checkbox */
.pdf-checkbox-container input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.pdf-input.pdf-checkbox-container:focus {
  outline: none;
  border: 1px solid #4d90fe;
  -webkit-box-shadow: 0px 0px 5px #4d90fe;
  box-shadow: 0px 0px 5px #4d90fe;
}

/* Create a custom checkbox */
.pdf-checkbox-checkmark {
  position: absolute;
  top: 10%;
  left: 10%;
  height: 80%;
  width: 80%;
  border: 1px solid black;
}

.pdf-checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.pdf-checkbox-container input:checked ~ .pdf-checkbox-checkmark:after {
  display: block;
}

.pdf-checkbox-container .pdf-checkbox-checkmark:after {
  left: 30%;
  top: 5%;
  width: 33%;
  height: 66%;
  border: solid black;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}