@media print {
  .nonprintable {
    display: none !important;
  }

  .app {
    #outdated,
    .banner {
      display: none;
    }

    .header.documents {
      display: none;
    }

    .main {
      overflow-y: visible;

      // Nav is hidden (see below); no need for margin
      &__content {
        margin-left: 0 !important;
      }

      &__content.documents .content__header {
        display: none;
      }

      &.documents {
        margin-top: 0;
      }
    }
  }

  // Links should appear as normal text; paper isn't hypermedia!
  a,
  .btn-link {
    color: inherit !important;
    text-decoration: none !important;
  }

  // MS Edge wants to print nav; nobody else does. Appease it.
  nav {
    display: none !important;
  }

  // Make good life choices about where to page break.
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }

  table,
  .card {
    page-break-inside: avoid;
  }

  .same-page {
    break-inside: avoid;
  }
}
