@import "~/styles/sharedUtilities.scss";

.app .main__content .opportunity-view {
  .opportunity .summary-container {
    display: flex;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    .photo {
      align-items: center;
      display: flex;
      flex-flow: column nowrap;
      padding: 0.5rem 1.5rem 0.5rem 0.5rem;

      @include media-breakpoint-down(md) {
        padding: 1rem;
        width: 100%;
      }
    }
  }

  .flex-grow-1 {
    padding-left: 0.5rem;
  }

  .card {
    margin: 0;
  }
}