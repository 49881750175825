.pwstrength {
  li {
    list-style: none;
  }

  li.pwstrength__req1:before {
    content: '•';
    margin-right: 0.5em;
  }

  li.pwstrength__req2:before {
    content: '◦';
    margin-right: 0.5em;
  }

  li.pwstrength__req1--ok:before {
    content: '\2713\0020';
    color: #59b961;
    margin-right: 0.5em;
  }

  li.pwstrength__req2--ok:before {
    content: '\2713\0020';
    color: #59b961;
    margin-right: 0.5em;
  }
}
