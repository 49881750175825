$skeleton-row-color: #f2f2f2;
$skeleton-darker-color: #e6e6e6;

@keyframes swiping {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

%skeleton-row-styles {
  background-color: $skeleton-row-color;
  border-radius: 5px;
  height: 12px;
  width: 100%;

  &:not(:first-child) {
    margin-top: 12px;
  }

  &:last-child {
    width: 75%;
  }
}

.skeleton-row {
  @extend %skeleton-row-styles;
}

.skeleton-title {
  @extend %skeleton-row-styles;
  margin-bottom: 16px;
  width: 40%;
}

.skeleton.is-animating {
  .skeleton-row,
  .skeleton-title {
    animation: swiping 3s ease infinite;
    background: linear-gradient(90deg, $skeleton-row-color 25%, $skeleton-darker-color 37%, $skeleton-row-color 63%);
    background-size: 400% 100%;
  }
}

