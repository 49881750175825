.fa-smile-o {
  color: #33ffb8;
}

.fa-frown-o {
  color: #ff334f;
}

.fa-meh-o {
  color: #ffdd33;
}
.testcoverage .popover {
  max-width: 75%;
}
