@import "./variables.scss";
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import "~react-image-gallery/styles/scss/image-gallery.scss";

html {
  -webkit-font-smoothing: antialiased;
}

h5,
.h6 {
  word-break: break-word;
}

.banner {
  @include media-breakpoint-down(md) {
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 1000;
    height: 62px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }
}

.grecaptcha-badge:not(.show) {
  visibility: hidden;
}

.app {
  background-color: $page-background-color;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;

  @include media-breakpoint-down(md) {
    height: 100%;
    min-height: 100vh;
  }

  .footer {
    background-color: $page-header-background-color;
  }

  .header {
    align-items: center;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.18);
    display: flex;

    @include media-breakpoint-down(md) {
      position: fixed;
      top: 0px;
    }

    &.is-preview {
      @include media-breakpoint-down(md) {
        position: fixed;
        top: $preview-banner-height;
      }
    }

    &.is-full-style-prelogin {
      background-color: #fff;
    }

    flex: 0 0 $page-header-height;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    z-index: $header-z-index;

    // get rid of annoying Chrome highlight
    button:active,
    button:focus {
      border: none;
      outline: none;
    }

    &__navtoggle {
      align-items: center;
      background: transparent;
      border: none;
      display: flex;
      flex-direction: column;
      height: $page-header-height;
      justify-content: center;
      margin: 0;
      padding: 0;
      outline: none;
      overflow: hidden;
      transition: width 300ms;
      width: 0;
      -webkit-appearance: none;

      @include media-breakpoint-down(md) {
        width: $page-header-height;
      }

      // react-gears Icon
      i {
        font-size: 30px;
      }

      .icon-text {
        text-transform: uppercase;
        font-size: 9px;
      }
    }

    &__firm {
      @include media-breakpoint-down(xs) {
        display: none;
      }

      margin-left: 0.5em;

      img {
        max-height: calc(#{$page-header-height} - 10px);
      }
    }

    &__filler {
      flex: 1 1 auto;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .footer {
    width: 100%;
    text-align: center;
  }

  .main {
    display: flex;
    flex: 1;
    position: relative;

    @include media-breakpoint-down(md) {
      margin-top: $page-header-height;
    }

    &.not-navigating {
      .main__nav {
        @include media-breakpoint-down(md) {
          transform: translate3d(-$nav-width, 0, 0);
        }
      }
    }

    &.is-preview {
      @include media-breakpoint-down(md) {
        margin-top: calc(#{$page-header-height} + #{$preview-banner-height});
      }

      .main__nav {
        @include media-breakpoint-down(md) {
          height: 100vh;
          position: fixed;
          top: calc(#{$page-header-height} + #{$preview-banner-height});
        }
      }
    }

    &__nav {
      position: absolute;
      width: $nav-width;
      top: 0;
      left: 0;
      bottom: 0;

      @include media-breakpoint-down(md) {
        height: 100vh;
        position: fixed;
        top: $page-header-height;
      }

      transform: translate3d(0, 0, 0);
      transition: transform 300ms;
      z-index: 1000;
      overflow-y: auto;
      flex-direction: column;
      justify-content: space-between;
      -webkit-overflow-scrolling: touch;

      ul {
        list-style-type: none;
        padding: 0;
        width: 100%;

        a {
          color: $nav-item-color;
          display: block;
          padding: 1rem;
          margin: auto;
          width: 100%;

          &:active {
            background-color: $nav-item-active-background-color;
          }

          &:focus {
            color: $nav-item-color;
            text-decoration: none;
          }

          &:hover {
            color: $nav-item-hover-color;
            text-decoration: none;
          }
        }

        .is-active:hover {
          background-color: $nav-item-active-background-color;
        }
      }
    }

    &__content {
      flex: 1;
      padding: 0 15px;
      transition: margin-left 300ms;

      .content__header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        flex-wrap: wrap;
        flex: 0 0 auto;
        margin: 0;
        overflow: hidden;

        >h1 {
          flex: 0 1 auto;
          font-size: 2.2em;
          margin: 0.3em 0 0 0;
          overflow: hidden;
          white-space: nowrap;
          font-weight: 400;

          @include media-breakpoint-down(sm) {
            font-size: 2em;
          }
        }

        >h5 {
          flex: 0 1 auto;
          margin: 0.3em 0 0.3em 0;
          font-weight: 400;
        }

        @include media-breakpoint-up(lg) {
          margin-right: 1rem;
        }
      }

      .card,
      .card-header {
        background-color: #fff;
      }

      .card {
        border: .75px solid #dedede;
        border-radius: 0.75rem;
        box-shadow: 0 3px 5px rgba(14, 37, 52, .1), 0 1px 1px rgba(14, 37, 52, .1) !important;
        padding: 0;
        margin: 1em 0;
      }

      .card-header {
        border-radius: .75rem;
        border-bottom: none;
        box-shadow: none;
      }

      .card-body {
        border-radius: .75rem;
      }

      .card-body .card-body {
        padding: 0.5rem;
      }

      .card-group .card, .recharts-wrapper .card {
        border: none !important;
        box-shadow: none !important;
      }

      .border-secondary {
        border: none;
        margin: 0;
      }

      .rg-ClickableContainer.jsx-1615642473:focus {
        outline: none;
      }

      .shadow-1 {
        background-color: $page-background-color;
      }

      @include media-breakpoint-up(lg) {
        margin-left: $nav-width;

        .card,
        .card-group {
          margin: 1em;
        }

        .card-group .card {
          margin: 0;
        }
      }
    }

    .no-nav {
      margin-left: 0px;
    }

    .table td {
      vertical-align: middle;
      border-top: 1px solid #f8f9fa;
      padding: 0.65rem;
    }

    &__content--prelogin {
      align-items: center;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      overflow: auto;

      >* {
        margin: auto;
        max-width: 35em;
      }

      .card {
        .buttons {
          a {
            margin: 0 1em;
          }
        }
      }
    }
  }

  .main--prelogin {
    @extend .main;
    margin-top: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .distribution-history-chart-block {
    .card-body {
      padding: 10px 5px !important;
    }
  }
}

@media only screen and (max-width: 576px) {
  .prelogin-background-image {
    background-image: none !important;
  }
}