/* For rich content; PLEASE COORDINATE between frontend (richContent.ts, RichContent.scss, index.html) and backend (index.html.erb)!!! */

$active-background-color: #d6d9e0;
$active-text-color: #000;
$button-border-radius: 3px;
$hover-background-color: #eaeaea;
$text-color: #333;
$text-field-border-radius: 0.25rem;

.ql-toolbar {
  border-top-left-radius: $text-field-border-radius;
  border-top-right-radius: $text-field-border-radius;
}

.ql-container {
  border-bottom-left-radius: $text-field-border-radius;
  border-bottom-right-radius: $text-field-border-radius;
  font-size: 1rem;
  font-family: inherit;
  overflow: hidden;
}

.ql-clipboard {
  white-space: normal;
}

.ql-editor {
  min-height: 20em;
  max-height: 50vh;
  overflow: scroll;
  word-break: break-word;

  img {
    vertical-align: initial;
  }
}

.small-editor .ql-editor {
  min-height: 10em;
}

.modal .ql-editor {
  max-height: 40vh;
}

.no-max-height .ql-editor {
  max-height: none;
}

.ql-snow.ql-toolbar {

  button,
  .ql-picker-label,
  .ql-picker-item {
    &:hover {
      background-color: $hover-background-color;
      border-radius: $button-border-radius;
      color: $text-color;

      .ql-stroke {
        stroke: $text-color;
      }

      .ql-fill {
        fill: $text-color;
      }
    }

    &.ql-active,
    &.ql-selected {
      color: $active-text-color;

      .ql-stroke {
        stroke: $active-text-color;
      }

      .ql-fill {
        fill: $active-text-color;
      }
    }
  }

  .ql-picker-item {
    &:hover {
      border-radius: 0;
    }
  }

  .ql-bold,
  .ql-italic,
  .ql-underline {
    margin-right: 1px;

    &.ql-active {
      background-color: $active-background-color;
      border-radius: $button-border-radius;
    }
  }

  .ql-align .ql-picker-item {
    width: 26px;
  }

  /* Set dropdown font-families */
  .ql-picker.ql-font {

    .ql-picker-label,
    .ql-picker-item {
      &::before {
        content: "Font";
      }

      &[data-value='arial, sans-serif']::before {
        content: "Sans Serif";
        font-family: arial, sans-serif;
      }

      &[data-value="'times new roman', serif"]::before {
        content: "Serif";
        font-family: "times new roman", serif;
      }

      &[data-value='monospace']::before {
        content: "Monospace";
        font-family: monospace;
      }

      &[data-value="'arial black', sans-serif"]::before {
        content: "Wide";
        font-family: "arial black", sans-serif;
      }

      &[data-value="'arial narrow', sans-serif"]::before {
        content: "Narrow";
        font-family: "arial narrow", sans-serif;
      }

      &[data-value="garamond, 'times new roman', serif"]::before {
        content: "Garamond";
        font-family: garamond, "times new roman", serif;
      }

      &[data-value='georgia, serif']::before {
        content: "Georgia";
        font-family: georgia, serif;
      }

      &[data-value='tahoma, sans-serif']::before {
        content: "Tahoma";
        font-family: tahoma, sans-serif;
      }

      &[data-value="'trebuchet ms', sans-serif"]::before {
        content: "Trebuchet MS";
        font-family: "trebuchet ms", sans-serif;
      }

      &[data-value='verdana, sans-serif']::before {
        content: "Verdana";
        font-family: verdana, sans-serif;
      }
    }
  }

  .ql-picker.ql-size {

    .ql-picker-label,
    .ql-picker-item {
      &[data-value="x-small"]::before {
        content: "Small";
      }

      &[data-value="x-large"]::before {
        content: "Large";
      }

      &[data-value="xx-large"]::before {
        content: "Huge";
      }
    }

    .ql-picker-item {
      &[data-value="x-small"]::before {
        font-size: x-small;
      }

      &[data-value="x-large"]::before {
        font-size: x-large;
      }

      &[data-value="xx-large"]::before {
        font-size: xx-large;
      }
    }
  }
}

// Save button in link toolbar option
.ql-tooltip a {

  &.ql-action,
  &.ql-remove {
    color: #0275d8;

    &:hover,
    &:focus {
      color: #0275d8;
    }
  }
}

.ql-picker.ql-placeholder {
  width: 118px;

  >span.ql-picker-label::before {
    content: "Insert Field";
  }

  >span.ql-picker-options {
    >span.ql-picker-item {
      margin: 0 -8px;
      padding: 5px 8px;

      ::before {
        content: attr(data-label);
      }
    }
  }
}

.ql-placeholder-content {
  background-color: $hover-background-color;
  border-radius: 6px;
  font-variant: titling-caps;
  margin: 2px 2px 10px 2px;
  padding: 0.1rem;
  vertical-align: bottom;
}

.ql-placeholder-dom-content {
  background-color: $hover-background-color;
  border-radius: 6px;
  font-variant: titling-caps;
  vertical-align: bottom;
  padding: 0.1rem;
  width: 50%;

  span {
    padding-left: 0.5rem;
  }
}

.ql-calltoaction-content {
  display: inline;

  .btn {
    text-decoration: none !important;
  }

  .btn-primary {
    background: --primary;
    color: white;
  }
}

.ql-picker.ql-insertSignature svg {
  display: none;
}

.ql-picker.ql-insertSignature>span.ql-picker-label::before {
  display: inline-block;
  width: 18px;
  content: url("data:image/svg+xml; utf8, <svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='signature' class='svg-inline--fa fa-signature fa-w-20' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'><path fill='currentColor' d='M623.2 192c-51.8 3.5-125.7 54.7-163.1 71.5-29.1 13.1-54.2 24.4-76.1 24.4-22.6 0-26-16.2-21.3-51.9 1.1-8 11.7-79.2-42.7-76.1-25.1 1.5-64.3 24.8-169.5 126L192 182.2c30.4-75.9-53.2-151.5-129.7-102.8L7.4 116.3C0 121-2.2 130.9 2.5 138.4l17.2 27c4.7 7.5 14.6 9.7 22.1 4.9l58-38.9c18.4-11.7 40.7 7.2 32.7 27.1L34.3 404.1C27.5 421 37 448 64 448c8.3 0 16.5-3.2 22.6-9.4 42.2-42.2 154.7-150.7 211.2-195.8-2.2 28.5-2.1 58.9 20.6 83.8 15.3 16.8 37.3 25.3 65.5 25.3 35.6 0 68-14.6 102.3-30 33-14.8 99-62.6 138.4-65.8 8.5-.7 15.2-7.3 15.2-15.8v-32.1c.2-9.1-7.5-16.8-16.6-16.2z'></path></svg>");

  // Changes black to #333.
  // Filter is needed to change the color as the normal ways of changing color didn't seem to work.
  // Google "hex to css filter" if you need to change the color
  filter: invert(15%) sepia(1%) saturate(0%) hue-rotate(185deg) brightness(97%) contrast(85%);
}

.ql-picker.ql-insertSignature>span.ql-picker-options>span.ql-picker-item {
  width: 150px;
  height: 60px;
  background-size: 150px;
  background-repeat: no-repeat;
  margin: 5px 0;

  &[data-value="manage-signatures"] {
    width: auto;
    height: auto;
  }
}

@-webkit-keyframes circleFadeDelay {

  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}

@keyframes circleFadeDelay {

  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}

.quill.fixed-width .ql-container {
  background: #EDF0F2;
}

.quill.fixed-width .ql-editor {
  background: white;
  margin: auto;
  max-width: 600px;
  overflow-x: hidden;
}