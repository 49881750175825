@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.im-spinner {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  animation: fade-in 2s;

  &.inline {
    display: inline-block;
    padding: 0;
    width: initial;
  }

  svg {
    background: none;

    circle.primary {
      fill: none;
      stroke: #0c99db;
      stroke-width: 3;
    }
    circle.secondary {
      fill: none;
      stroke: #bfe8fb;
      stroke-width: 3;
    }
  }
}
