$space-between-pages: 15px;

.pdf-scroll-container {
  min-height: 750px;
  max-height: 1000px;
  overflow: auto;
  border-left: $space-between-pages solid #dedede;
  border-right: $space-between-pages solid #dedede;
  border-top: $space-between-pages solid #dedede;
  border-bottom: $space-between-pages solid #dedede;
  background: #dedede;

  .pdf-scroll-container,
  .react-pdf__Page.pdf-page,
  .react-pdf__Page__canvas {
    /* hack to get the horizontal scroll bar to show on .pdf-container */
    overflow: hidden;
  }
}

.pdf-page-container {
  position: relative;
  margin-bottom: $space-between-pages;
  &:last-child {
    margin-bottom: 0;
  }
}
