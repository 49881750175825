@import "../../../styles/variables.scss";
@import "../../../styles/sharedUtilities.scss";

.main.not-navigating.Opportunities {
  background: #fcfcfc;
}

.interest-body {
  max-width: 990px;
  margin-left: auto;
  margin-right: auto;
}

.offering-body {
  max-width: 1250px;
  padding: 0 !important;
  margin-left: auto;
  margin-right: auto;
  background: #fcfcfc;
}

.page-margins {
  @include media-breakpoint-down(md) {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.offering-body .backlink {
  margin-left: 1rem;
}

.link-below {
  margin-top: -1.1rem;
  padding-left: 0;
  margin-bottom: 0.5rem;
}

.sticky-header {
  position: sticky;
  z-index: $header-z-index - 1;
  top: 0;
  background-color: #fff;
  max-width: 1250px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: .75px solid #dedede;
}

ul {
  font-family: "Avenir", "Nunito Sans", sans-serif;
}

.content__header {
  margin-right: 0 !important;
  padding-bottom: 0.5rem !important;
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  height: 2rem;
  width: 1rem;
}

.image-gallery-left-nav, .image-gallery-right-nav{
  padding: 0.25rem .75rem;
}

.image-gallery-icon{
  color: #999;
  border: 1px solid #dedede;
  background: #fff;
  border-radius: 0.5rem;
  -webkit-filter: none;
  filter: none;
}

.image-gallery-icon:hover {
  border-color: #949494;
  color: #333333;
}