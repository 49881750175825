///////////////////////////////////////////////////////////
// Temp styles that should go away once IM has an official Bootstrap theme.
// These mimic some Bootstrap 3 design elements that have gone away.
///////////////////////////////////////////////////////////

$secondary: #e7e7e7;

td a, .btn-link, .contact_us a {
  color: #2f75d1 !important;
}

.btn-link {
  border: none;
  outline: none;
  color: #2f75d1;
}

.header {
  .btn-secondary {
    border: none;
    outline: none;
    color: black;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  .btn-secondary:hover {
    color: black;
    background-color: white;
    border: none;
    outline: none;
  }
  .btn-secondary:focus {
    color: black;
    background-color: white;
    border: none;
    outline: none;
  }
  .dropdown-item {
    padding: 0.5rem 1.5rem;
  }

  .dropdown-item:hover {
    cursor: pointer;
  }
}

.nav {
  .btn-outline-secondary {
    border: 1px solid #ccc;
    color: #ccc;
  }
}

///////////////////////////////////////////////////////////
// Other hacks
///////////////////////////////////////////////////////////

// TODO figure out why buttons are normally so ugly in feedback modal .. not necessarily theme related.
.modal-footer {
  a,
  button {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
}

.badge-secondary {
  color: #303030;
  background-color: #ccc;
}

[role="button"] {
  cursor: pointer;
}

.row-default-cursor {
  cursor: auto !important;
}

.no-pointer-events {
  pointer-events: none !important;
}
