@media screen {
  .nonscreenable {
    display: none;
  }

  .app {
    .main {
      overflow-y: hidden;

      &__content {
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
      }
    }
  }

  // TODO: use BEM notation (I am really .main__body) and express my max width as inches
  .main-body {
    max-width: 84.375rem;
    padding: 0 !important;
    margin-left: auto;
    margin-right: auto;
  }
  
  // TODO: use BEM notation (I am really .main__body--narrow) and express my max width as inches
  .main-body-narrow {
    max-width: 61.875rem;
    padding: 0 !important;
    margin-left: auto;
    margin-right: auto;
  }
}
