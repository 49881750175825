.our-team-row {
  .profile-image {
    height: 10rem;
    width: 10rem;
    object-fit: cover;
    margin: auto;
    display: block;
    border-radius: 50%;
  }

  .empty-profile-image {
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
    margin: auto;
    background-color: #dadada !important;
  }

  .team-member-name {
    font-size: 1.6rem;
    font-weight: normal;
    font-family: "Avenir", "Nunito Sans", sans-serif;
  }

  .team-member-title {
    font-size: 1.25rem;
    font-weight: bold;
    font-family: "Avenir", "Nunito Sans", sans-serif;
  }

  .team-member-bio {
    white-space: pre-wrap;
    font-size: 1rem;
    font-weight: normal;
  }
}