///////////////////////////////////////////////////////////
// Base styles for all IM UIs
// NB: PLEASE KEEP THIS FILE IDENTICAL between all projects! It should become
// shared code. For now, copy-paste whenever you edit it.
///////////////////////////////////////////////////////////

@import "./bootstrap.scss";
@import "./variables.scss";

html {
  -webkit-font-smoothing: antialiased;
}

table,
.table {
  font-variant-numeric: tabular-nums;
  max-width: none; // fixes safari issues with scrolling and shading tables
}

.table thead th {
  border-top: 0px;
  border-bottom: 0px;
  white-space: nowrap;
  -webkit-box-shadow: inset 0 -1px 0 #b9b9b9;
  box-shadow: inset 0 -1px 0 #b9b9b9;
}

.table td {
  vertical-align: middle;
  border-top: 1px solid #f8f9fa;
  padding: 0.65rem;

  .list-unstyled {
    margin: 0;
  }
}

.modal-footer {
  justify-content: flex-start !important;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}


.dropdown-item:disabled,
.dropdown-item.disabled {
  pointer-events: auto;
}

.photos-row {
  width: 3rem;

  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.thumb-table {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #f8f9fa;
  opacity: 0.9;
}

.no-thumb {
  height: 2.5rem;
}

img.thumb-card {
  width: 12em;
  height: 12em;
  object-fit: cover;
}

.fundraising-description {
  white-space: pre-wrap;
  word-break: break-word;
  margin-top: 0.5rem;
}

input:invalid {
  box-shadow: none;
}

.text-wrap {
  word-break: break-word;
}

.table-with-summary tbody tr:last-child {
  font-weight: bold;
}

// disable the little 'X' on IE and Edge that clears input fields
input::-ms-clear {
  visibility: hidden;
}

.btn-primary {
  background-color: #0275d8;
  border: 1px solid #0275d8;
}

.btn-primary,
.btn-secondary {
  padding: 0.25rem 0.75rem;
}

.btn-secondary {
  background-color: #eceeef;
  border-color: #eceeef;
  color: #303030;
}

.btn-secondary:hover {
  background-color: #dde0e6;
  border-color: #919cab;
  color: #303030;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #303030;
  background-color: #f3f4f6;
  border-color: #aeb6c1;
}

.sticky-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 8;

  button {
    height: inherit;
    border-radius: 0;
  }
}

$invest_stick_btn_bp: map-get($grid-breakpoints, "lg");

.sticky-footer {
  display: none;

  @media (max-width: $invest_stick_btn_bp) {
    display: block;
    height: 2.5rem;
    font-size: 1.1rem;
    letter-spacing: .5px;
  }
}

.sticky-footer-space {
  display: none;
  height: 3em;

  @media (max-width: $invest_stick_btn_bp) {
    display: block;
  }
}

.sticky-header .invest-btn {
  letter-spacing: 0.5px;

  @media (max-width: $invest_stick_btn_bp) {
    display: none;
  }
}

// Start - Label & Checkbox styling

.col-form-label {
  text-transform: none;
  font-size: 0.9rem;
  color: #6c757d;
  line-height: 1.5rem;
  margin-top: 0.1rem;

  @media (max-width: map-get($grid-breakpoints, "sm")) {
    line-height: 1rem;
    padding-top: 0.5rem;
  }
}

label,
.label {
  text-transform: none;
  font-size: 0.9rem;
  color: #6c757d;
  line-height: 1.5rem;
  margin-top: 0.1rem;
}

.modal-label {
  text-transform: none;
  font-size: 0.9rem;
  color: #6c757d;
  line-height: 1.5rem;
  margin-right: 0.25rem;
}

.form-check-input:not([type="radio"]) {
  position: initial;
  margin-left: 1rem;
}

.form-check {
  padding-bottom: 0.2rem;
}

.checkbox,
.form-check-label {
  padding-left: 0.5rem;
  text-transform: initial;
  font-size: 1rem;
  color: #303030;
  margin-top: auto;
  margin-bottom: auto;
}

input[type="checkbox"] {
  min-width: 16px;
  max-width: 16px;
}

.col-form-label.form-check {
  margin-left: -2rem;
  margin-top: -0.5rem;
}

// End - Label & Checkbox styling

.notification-icon {
  min-width: 20px;
  border-radius: 50%;
  margin-left: 0.5rem;
  vertical-align: text-bottom;
  color: #212529;
  background-color: #ffc107;
}

.performance {
  min-height: 126px;
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.set-width-90 {
  width: 90px;
}

.set-min-width-90 {
  min-width: 90px;
}

.set-width-450 {
  width: 450px;
}

.set-width-720 {
  width: 720px;
  word-break: break-word;
}

// overwriting default hasMany delete button styling

.btn-outline-danger {
  border: 0 !important;
  margin-bottom: 1rem;
}

.mb-4.no-gutters.row {
  margin-bottom: 0 !important;
}

// Has Many Fields override CSS for Add Button

.has-many-fields-add,
.has-many-fields-add:active {
  color: #0275d8 !important;
  display: inline-block;
  text-align: left;
  width: auto;
  border: none;
  background-color: transparent !important;
  margin-bottom: 1.5rem;
  padding: 0.5rem;
}

.has-many-fields-add:hover {
  color: #0275d8;
  background-color: transparent;
  text-decoration: underline;
}

//checkbox label override
.checkbox-label {
  font-size: 1rem;
  color: inherit;
  text-transform: initial;
}

.dark-left-border {
  border-left-color: #333333 !important;
}

@font-face {
  font-family: Avenir;
  src: url("https://af-im-ui.s3.us-east-2.amazonaws.com/static-assets/fonts/avenir/TTF/AvenirLTStd-Book.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Avenir;
  src: url("https://af-im-ui.s3.us-east-2.amazonaws.com/static-assets/fonts/avenir/TTF/AvenirLTStd-Heavy.otf");
  font-weight: 700;
  font-style: bold;
}


.gp-note .card-body {
  padding: 1.25rem;
}

.btn {
  padding: 0.35rem 0.75rem 0.25rem 0.75rem;
  white-space: nowrap;
}

.btn-secondary {
  background-color: #eceeef;
  border-color: #eceeef;
}

.btn-secondary:hover,
.btn-secondary:active .btn-secondary:focus {
  border-color: #dde0e6;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  border-color: #f3f4f6;
}

.btn-group .btn-secondary,
.btn-group .btn-secondary:hover,
.btn-group .btn-secondary:active {
  color: #303030;
  font-size: 1.1rem;
  background-color: #fff !important;
  border: none !important;
  border-bottom: 4px solid transparent !important;
  border-radius: 0rem !important;
  padding: 0.2rem 0.5rem 0.15rem 0.5rem;
  margin-right: 0.5rem;
}

.btn-group .btn-secondary:hover {
  color: #0275d8 !important;
}

.btn-group .btn-secondary.active {
  color: #303030;
  font-family: "Avenir", "Nunito Sans", sans-serif;
  font-weight: 600;
  background-color: #fff !important;
  border-bottom: 4px solid #17a2b8 !important;
}

.input-group-append .btn-secondary {
  border-color: #ced4da;
}

.btn-secondary:focus {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

img {
  border-radius: 0.25rem;
}

.header_logo img {
  border-radius: 0;
}

h2,
h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.nav li,
li.nav-item,
.header,
.backlink,
.btn-primary,
.btn-secondary,
.btn-danger,
.btn-group,
.btn-success,
.btn-link,
.alert,
.Select-value-label,
.Select-placeholder,
.dropdown-item {
  font-family: "Avenir", "Nunito Sans", sans-serif;
  font-weight: 400;
}

a {
  color: #2f75d1;
}

h1,
.h1 {
  font-family: "Avenir", "Nunito Sans", sans-serif;
  font-size: 2rem !important;
  font-weight: 700 !important;
  color: #444;
}

#action-items a,
.contact_us a {
  font-family: "Avenir", "Nunito Sans", sans-serif;
}

.main__nav a.disabled-nav-item,
.main__nav a.disabled-nav-item:hover {
  background-color: rgba(0.5, 0.5, 0.5, 0.5) !important;
}

table .btn-link,
.form-group .btn-link {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.table-striped tbody tr:nth-of-type(odd) {
  background: #f8f9fa;
}

.table-striped tbody tr:nth-of-type(even) {
  background: #fff;
}


.table-hover tbody tr:hover {
  background-color: #E3FCFF;
}

.app .main__nav ul a {
  font-size: 18px;
  padding: 1rem 1rem 0.9rem;
}

.h3 {
  font-weight: 400;
}

.tooltip-icon {
  font-size: .8rem !important;
  padding-left: .25rem !important;
  color: #6c757d !important;
}

h5, .h5 {
  padding-top: 0.25rem;
  font-size: 1.35rem;
  font-weight: 700;
  margin-bottom: 1.25rem;
  color:#333;
}

.alert-info {
  background-color: #dff0f3c9;
  border-color: #dff0f3c9;
  vertical-align: middle;
}

.alert-secondary {
  background: #eceeef;
}

.countryCode {
  max-width: 135px;
  min-width: 100px;
}

.phoneNumber {
  min-width: 165px;
}

.light-gray {
  color: #a3a3a3;
}

.legal-text {
  font-size: 90%;
  font-family: "Avenir", "Nunito Sans", sans-serif;
  font-weight: 400;
}

.modal.mfamodal {
  z-index: 1080;
}

.modal-backdrop.mfamodal {
  z-index: 1070;
  opacity: 0.8;
}

:focus:not(:focus-visible) {
  outline: none;
  --webkit-box-shadow: none;
  box-shadow: none;
}

.block-datapair-label {
  font-size: .9rem;
  line-height: 1.5rem;
}

.block-datapair-value {
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: right;
  max-width: 60%;
}

//removing mouse focus, still leaves keyboard focus

.page-header {
  margin-left: 1rem;

  @media (max-width: map-get($grid-breakpoints, "md")) {
    margin-left: 0;
  }
}

.hide-small {
  @media (max-width: map-get($grid-breakpoints, "md")) {
    display: none;
  }
}

.Select-menu-outer {
  min-width: 250px;
  overflow-x: auto;

  .Select-menu {
    min-width: max-content;
    overflow-y: initial;
  }

  .dropdown-item {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .Select-option-group {
    .Select-option-group-label {
      font-weight: 600;
      padding: 0.5rem 0 0.5rem 0.9rem;
    }

    .dropdown-item {
      padding-left: 1.8rem;
      padding-right: 1rem;
    }
  }
}

.recharts-tooltip-wrapper {
  outline: none;
}

#root > .im-spinner {
  width: 100vw;
  height: 100vh;
}

.im-spinner {
  margin: 0;
  padding: 1em;
}

@import "./fix-import-order-issues.scss";
