.slideshow {
  align-items: center;
  background: black;
  display: flex;
  flex-flow: column nowrap;
  position: fixed;
  left: 0;
  top: 60px;
  width: 100vw;
  height: calc(100vh - 60px);
  z-index: 9999;

  .close-slideshow {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
  }

  button.btn-link {
    color: white;
    font-size: 150%;
    font-weight: bold;
    height: 100%;
    z-index: 99;
  }

  .carousel {
    width: 100%;
    height: 100%;

    .carousel-item {
      text-align: center;
      img {
        width: 100vw;
        height: 100vh;
        object-fit: scale-down;
      }
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      width: 2rem;
      height: 2rem;
      cursor: pointer;
    }

    .carousel-indicators > li {
      border-radius: 1rem;
      width: 0.75rem;
      height: 0.75rem;
      margin-right: 0.5rem;
    }
  }
}
