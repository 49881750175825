@import "~/styles/sharedUtilities.scss";

.recent-offering-view-button {
  max-width: 9em;
}

.recent-offering-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 350px);
  grid-auto-rows: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 2rem;
  @media (max-width: 793px) {
    justify-content: center;
  }
}

.recent-offering-card .summary-container {
  height: 100%;

  align-self: stretch;

  .flex-grow-1 {
    padding-left: 0;
  }

  .photo {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    padding: 1rem;
    cursor: pointer;
    outline: none;

    img {
      width: 100%;
      height: 15rem;
      object-fit: cover;
    }
  }
}

.recent-offering-card {
  width: 100%;
  margin: 0 !important;
  @include media-breakpoint-up(sm) {
    width: 350px;
  }
}
