@import "~/styles/sharedUtilities.scss";

.fundraising-steps.top-align-td {
  .table tr td {
    vertical-align: top;
  }

  @media (max-width: map-get($grid-breakpoints, "sm")) {
    .btn-link {
      padding: 0;
    }

    .btn:not(.btn-link) {
      margin-top: .75rem;
    }
  }
}